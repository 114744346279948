/* опции для каруселей */

const swiperOptions = {
	/* опции карусели на главной (букмекеры),
	отключено на мобильных */
	'best' : {
		slidesPerView: 5,
		// Responsive breakpoints
	  breakpointsInverse: true,
	  breakpoints: {
	  	// when window width is >= 320px
	    320: {
	    	slidesPerView: 2
	    },
	    // when window width is >= 576px
	    576: {
	      slidesPerView: 3
	    },
	    // when window width is >= 768px
	    768: {
	      slidesPerView: 4
	    },
	    // when window width is >= 1200px
	    992: {
	      slidesPerView: 5
	    }
	  },
		// Navigation arrows
    navigation: {
      nextEl: '.swiper-best-next',
      prevEl: '.swiper-best-prev',
    },
    autoplay: {
      delay: 4000,
    }
	},
}


const documentInit = () => {
	const body = getDomElem('body')
	const htmlTag = getDomElem('html')

	const bodyToggleClassName = function() {
		let { class : className}  = this.dataset
		body.classList.toggle(className)
		htmlTag.classList.toggle(className)
	}

	const elemToggleClassName = function() {
		let { parent } = this.dataset
		let elem = getDomElem(parent)
		elem.classList.toggle('show')
	}

	const prepareTable = elems => {
		elems.forEach(table => {
			table.classList.add('table')

			let thead = table.querySelector('thead')
			thead.classList.add('thead-light')

			let inner = document.createElement('div')
			inner.classList.add('table-inner')

			let wrapper = document.createElement('div')
			wrapper.classList.add('table-responsive')

			let shadow = document.createElement('div')
			shadow.classList.add('table-shadow')

			pipe(
				wrap(inner),
				wrap(wrapper),
				wrap(shadow)
			)(table)
		})
	}


	const scrollToElem = function(e) {
		pipe(
			getDomElem,
			animateScroll
		)(this.dataset.target)
	}

	pipe(
		getDomElems,
		setActionEventElems(bodyToggleClassName)
	)('.toggler') // переключают в <body> класс в .toggler data-class

	pipe(
		getDomElems,
		setActionEventElems(elemToggleClassName)
	)('.toggler-parent') // переключают в data-parent класс show

	/*pipe(
		getDomElem,
		setHammer(bodyToggleClassName, EVENTS.SWIPELEFT)
	)('.page-sidebar-inner')*/ // свайп в лево, для закрытия меню

	/* таблицы в контенте */
	pipe(
		getDomElems,
		prepareTable 
	)('table:not([class])')

	/* карусели */
	/* опции, брекпоинт и направление отключение свайпера */
	/* карусель "Лучшие легальные букмекеры в России" на главной */
	initSwiper('.swiper-best', swiperOptions['best'])


  /* добавит / удалит класс show у элемента, после прокрутки на сколько пиикселей */
  toggleShowAfterScroll('.banner-bottom-fixed', 300)

  /* скролл */
  pipe(
		getDomElems,
		setActionEventElems(scrollToElem)
	)('.btn-scroll')

	$('.sidebar-left-scroll').scrollbar()

}

window.onload = documentInit