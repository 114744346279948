"use strict";



/* используемые события */
const EVENTS = {
	CLICK      : 'click',

	// hummer events
	SWIPELEFT  : 'swipeleft',
	SWIPERIGHT : 'swiperight',
	SWIPEUP    : 'swipeup',
	SWIPEDOWN  : 'swipedown'
}



/* композиция */
const pipeFn = (pipe, f) => f(pipe) 
const pipe = (...fns) => arg => fns.reduce(pipeFn, arg)



/* ссылки на элементы */
const getDomElem  = selector => document.querySelector(selector)
const getDomElems = selector => [...document.querySelectorAll(selector)]



/* обернуть элемент */
const wrap = wrapper => el => {
	el.parentNode.insertBefore(wrapper, el)
  wrapper.appendChild(el)

  return wrapper
}



/* добавить обработку события */
const setActionEventElem  = (cb, event = EVENTS.CLICK) => el  => el && el.addEventListener(event, cb.bind(el))
const setActionEventElems = (cb, event = EVENTS.CLICK) => arr => arr.forEach(el => el.addEventListener(event, cb.bind(el)))



/* libraries init */
const setHammer           = (cb, event = EVENTS.SWIPELEFT, options = {}) => el  => new Hammer(el, options).on(event, cb.bind(el))
const setSwiper           = (el, options = {}) => new Swiper(el, options)



/* получить опции и ссылку на объект DOM для карусели */
//const getSwiperOption = (options = {}) => el => ({ el, options })



/*
	* @param breakpoint - точка отключения/включения swiper значения, 575, 767, 991, 1199
	* @param direction - направление отключение swiper
		1. передать точку брекпоинта, направление отключение свайпера
		2. передать ссылку на dom element, опции свайпера 
		3. Если надо запускаем слайдер, если нет, ничего не делаем
		4. Ловим событие ресайза окна
		5. если были запущены и надо запускать ничего не делаем
		6. если не были и не надо, ничего не делаем
		7. если не были запущены и надо запускать, запускаем
		8. если было включено и надо отключить, отключаем
	*/
const initOrDestroySwiper = (options = {}, breakpoint = 0, direction = 'down') => 
  (el = undefined) => {

  // если элемент не найден, выходим
	if(!el) return

	// если нет breakpoint, запускаем с настройками
	if(breakpoint === 0 || !['down', 'up'].includes(direction)) {
		setSwiper(el, options)
		return 
	} 

	let instance = undefined

	const initSwiper = () => {
		const documentWidth = document.documentElement.clientWidth

		if(direction === 'down') { // если отключаем на меньших экранах
			if(documentWidth > breakpoint && instance === undefined) {
				instance = setSwiper(el, options)
			} else if(documentWidth <= breakpoint && instance !== undefined) {
				instance.destroy()
				instance = undefined
			}
		} else if(direction === 'up') { // если отключаем на больших экранах
			if(documentWidth <= breakpoint && instance === undefined) {
				instance = setSwiper(el, options)
			} else if(documentWidth > breakpoint && instance !== undefined) {
				instance.destroy()
				instance = undefined
			}       
		}   
	}

	window.addEventListener('resize', initSwiper)
	initSwiper()
}



/* запуск каруселей */
const initSwiper = (selector, options = {}, breakpoint = 0, direction = 'down') => 
	pipe(
		getDomElem,
		initOrDestroySwiper(options, breakpoint, direction)
	)(selector)



/* плавный скролл к элементу */
const animateScroll = elem => elem.scrollIntoView({
															  behavior: "smooth",
															  block:    "center" 
															})
	



 /* показать / скрыть элемент, после прокрутки на сколько пикселей */
const toggleShowAfterScroll = (selector, elemOffsetTop) => {

	const windowScrollListener = elemOffsetTop => el => {
		if(!(el && elemOffsetTop)) return

		// флаг на необходимость выполнения действия
		let flag = false

		window.addEventListener('scroll', () => {
	    let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop

	    if(elemOffsetTop <= scrollPosition && !flag) {
	    	el.classList.add('show')
	    	flag = true
	    } else if(elemOffsetTop > scrollPosition && flag) {
	    	el.classList.remove('show')
	    	flag = false
	    }
	  })
	}
		
  pipe(
		getDomElem,
		windowScrollListener(elemOffsetTop)
	)(selector)
}
